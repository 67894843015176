<template>
  <div class="navbar-footer container">
    <div class="logo-spotify-container">
      <a href="https://spotify.com" target="_blank">
        <img v-if="optionsStore.theme == 'dark'" class="logo-spotify" src="@/assets/images/Spotify_logo_full_green.png" width="115" />
        <img v-else class="logo-spotify" src="@/assets/images/Spotify_logo_full_black.png" width="115" />
      </a>
    </div>
    <div class="credits-spotify">
      {{ $t("footer.pasAffilieASpotify") }}
    </div>
    <div class="logout-et-copyright">
      <span v-if="spotifyStore.accessToken" v-on:click="deconnexion">{{ $t("footer.deconnexion") }}<br /></span>
      {{ `${$t("footer.copyright1")} ${new Date().getFullYear()} ${$t("footer.copyright2")}` }}
    </div>
  </div>
</template>

<script>
import { useSpotifyStore } from "@/stores/spotifyStore";
import { useOptionsStore } from "@/stores/optionsStore";

export default {
  name: "Footer",
  setup() {
    const spotifyStore = useSpotifyStore();
    const optionsStore = useOptionsStore();
    return { spotifyStore, optionsStore };
  },
  methods: {
    deconnexion() {
      this.spotifyStore.deconnexion();
      this.$router.push({ name: "Connexion" });
    },
  },
};
</script>

<style scoped>
.container {
  height: 90px;
  background-color: var(--couleur-fond-var);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .container {
    height: 150px;
  }
}

.logo-spotify-container {
  text-align: left;
  width: 130px;
}

.credits-spotify {
  text-align: left;
  width: calc(60% - 130px);
  font-size: 0.9rem;
}

@media screen and (max-width: 800px) {
  .credits-spotify {
    text-align: right;
    width: calc(100% - 130px);
  }
}

.logout-et-copyright {
  text-align: right;
  width: 40%;
  font-size: 0.9rem;
}

@media screen and (max-width: 800px) {
  .logout-et-copyright {
    text-align: center;
    width: 100%;
  }
}

.logout-et-copyright > span {
  text-decoration: underline;
  cursor: pointer;
}
</style>
