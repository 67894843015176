<template>
  <div class="container-page">
    <h2 class="titre-page">{{ $t("redirectionConnexion.titre") }}</h2>
    <div class="separateur"></div>
    <Chargement />
  </div>
</template>

<script>
import Chargement from "../components/Utilitaires/Chargement.vue";
import { useSpotifyStore } from "@/stores/spotifyStore";

export default {
  name: "RedirectionConnexionView",
  setup() {
    const spotifyStore = useSpotifyStore();
    return { spotifyStore };
  },
  components: {
    Chargement,
  },
  async created() {
    const code = this.$route.query.code;

    if (code) {
      await this.spotifyStore.definirAccessToken(code);
      await this.spotifyStore.definirUserId();
    }

    this.$router.push({ name: "Generation" });
  },
};
</script>
