import { defineStore } from "pinia";

export const useOptionsStore = defineStore("options", {
  state: () => ({
    langue: null,
    theme: null,
  }),
  getters: {},
  actions: {},
});
