<template>
  <div class="container-page">
    <h2 class="titre-page">{{ $t("connexion.titre") }}</h2>

    <div class="separateur"></div>

    <span v-html="$t('connexion.description')"></span>

    <div class="separateur"></div>

    <a class="btn-connexion" :href="urlConnexionSpotify">
      <img class="logo-spotify" src="@/assets/images/Spotify_logo.png" />
      <span>{{ $t("connexion.btnConnexionSpotify") }}</span>
    </a>

    <div class="separateur"></div>

    <span v-html="$t('connexion.instructionsConnexion')"></span>
  </div>
</template>

<script>
import spotifyConfig from "../constants/spotifyConfig";

export default {
  name: "ConnexionView",
  computed: {
    urlConnexionSpotify() {
      return (
        `${spotifyConfig.AUTH_ENDPOINT}?client_id=${spotifyConfig.ID_CLIENT}&scope=${spotifyConfig.SCOPES}` +
        `&response_type=${spotifyConfig.TYPE_REPONSE}&redirect_uri=${window.location.origin + spotifyConfig.REDIRECT_URI}`
      );
    },
  },
};
</script>

<style scoped>
.btn-connexion {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  height: 1.5rem;
  border-radius: 2rem;
  background-color: #1db954;
  color: white;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
}

.logo-spotify {
  height: 24px;
  padding-right: 0.75rem;
}
</style>
