const urlBase = "https://api.spotify.com/v1/";
const urlBackend = "https://eit75usso9.execute-api.ca-central-1.amazonaws.com/latest/";

export default {
  URL_RECHERCHE: urlBase + "search",
  URL_PLAYLIST: urlBase + "playlists/{playlist_id}",
  URL_PLAYLISTS: urlBase + "users/{user_id}/playlists",
  URL_PROFIL: urlBase + "me",
  URL_CHANSONS: urlBase + "playlists/{playlist_id}/tracks",
  URL_LIKED_SONGS: urlBase + "me/tracks",
  URL_ACCESS_TOKEN: urlBackend + "spotify/accessToken",
};
