<template>
  <div class="switch-container" v-on:click="changerValeurToggle">
    <div class="option option-1" :class="!valeur && 'option-selected'"><slot name="option1"></slot></div>
    <div class="option option-2" :class="valeur && 'option-selected'"><slot name="option2"></slot></div>
  </div>
</template>

<script>
export default {
  name: "Switch",
  data() {
    return {
      valeur: false,
    };
  },
  props: {
    nomValeur: {
      type: String,
    },
    valeurDefaut: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changerValeurToggle() {
      this.valeur = !this.valeur;
      localStorage.setItem(this.nomValeur, this.valeur);
      this.$emit("changementValeur", this.valeur);
    },
  },
  created() {
    this.valeur = this.valeurDefaut;
    const valeurCache = localStorage.getItem(this.nomValeur);
    if (valeurCache != null) this.valeur = valeurCache === "true";
  },
};
</script>

<style scoped>
.switch-container {
  display: flex;
  cursor: pointer;
}

.option {
  background-color: white;
  color: var(--couleur-accent);
  border: 2px solid var(--couleur-accent);
  font-weight: bold;
  user-select: none;
  display: flex;
  height: 25px;
  width: 30px;
  align-items: center;
  justify-content: center;
}

.option-selected {
  background-color: var(--couleur-accent);
  color: white;
}

.option-1 {
  border-radius: 10px 0 0 10px;
}

.option-2 {
  border-radius: 0 10px 10px 0;
}
</style>
