<template>
  <div class="navbar-footer container">
    <Switch nomValeur="langue" :valeurDefaut="langueNavigateurValeur" v-on:changementValeur="changerLangue">
      <template v-slot:option1>FR</template>
      <template v-slot:option2>EN</template>
    </Switch>
    <img id="logo" src="@/assets/images/Blendify_logo_full.png" alt="Logo" v-on:click="this.$router.push({ name: 'Generation' })" />
    <Switch nomValeur="theme" v-on:changementValeur="changerTheme">
      <template v-slot:option1>
        <i class="material-icons">light_mode</i>
      </template>
      <template v-slot:option2>
        <i class="material-icons">dark_mode</i>
      </template>
    </Switch>
  </div>
</template>

<script>
import Switch from "../Utilitaires/Switch.vue";
import { useOptionsStore } from "@/stores/optionsStore";

export default {
  name: "Navbar",
  setup() {
    const optionsStore = useOptionsStore();
    return { optionsStore };
  },
  components: {
    Switch,
  },
  methods: {
    changerTheme(valeur) {
      const theme = valeur ? "dark" : "light";
      document.documentElement.setAttribute("theme", theme);
      this.optionsStore.theme = theme;
    },
    changerLangue(valeur) {
      const langue = valeur ? "en" : "fr";
      this.$i18n.locale = langue;
      this.optionsStore.langue = langue;
    },
  },
  computed: {
    langueNavigateurValeur() {
      const langueNavigateur = navigator.language.substring(0, 2);
      return langueNavigateur === "en" ? true : false;
    },
  },
};
</script>

<style scoped>
.container {
  height: 55px;
  background-color: var(--couleur-fond-var);
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

#logo {
  height: 45px;
  cursor: pointer;
}

.material-icons {
  font-size: 1.25rem;
}
</style>
