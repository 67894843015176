import { defineStore } from "pinia";
import spotifyApiRoutes from "../constants/spotifyApiRoutes";
import axios from "axios";
import spotifyConfig from "../constants/spotifyConfig";

export const useSpotifyStore = defineStore("spotify", {
  state: () => ({
    accessToken: null,
    userId: null,
    userLikedSongs: null,
    lienDerniereListe: null,
  }),
  getters: {},
  actions: {
    async definirAccessToken(code) {
      const data = {
        grant_type: spotifyConfig.GRANT_TYPE,
        code: code,
        redirect_uri: window.location.origin + spotifyConfig.REDIRECT_URI,
      };

      try {
        const retourBackend = await axios.post(spotifyApiRoutes.URL_ACCESS_TOKEN, data);
        this.accessToken = retourBackend.data;
        sessionStorage.setItem("accessToken", this.accessToken);
      } catch (e) {
        console.error(e);
      }
    },
    deconnexion() {
      this.accessToken = null;
      sessionStorage.removeItem("accessToken");
    },
    async definirUserId() {
      try {
        const profil = await axios.get(spotifyApiRoutes.URL_PROFIL, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        });
        this.userId = profil.data.id;
        sessionStorage.setItem("userId", this.userId);
      } catch (e) {
        console.error(e);
      }
    },
    async definirUserLikedSongs() {
      try {
        this.userLikedSongs = [];
        let pageCourante = 0;
        let dernierePage = false;

        while (!dernierePage) {
          const likedSongs = await axios.get(spotifyApiRoutes.URL_LIKED_SONGS, {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
            params: {
              limit: spotifyConfig.REQUEST_LIMIT,
              offset: pageCourante,
            },
          });
          pageCourante += spotifyConfig.REQUEST_LIMIT;
          this.userLikedSongs = this.userLikedSongs.concat(
            likedSongs.data.items.map((i) => {
              return { uri: i.track.uri, artist: i.track.artists[0].id, name: i.track.name };
            })
          );
          if (likedSongs.data.items.length < spotifyConfig.REQUEST_LIMIT) dernierePage = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
});
