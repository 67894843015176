import { createApp } from "vue";
import { createPinia } from "pinia";
import { vMaska } from "maska";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import fr from "./locales/fr.json";

const i18n = createI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages: {
    en: en,
    fr: fr,
  },
});

createApp(App).directive("maska", vMaska).use(router).use(i18n).use(createPinia()).mount("#app");
