const idClient = "2d2b328718844e13b023efb2c2adec3c";
const redirectUri = "/redirection-connexion";
const authEndpoint = "https://accounts.spotify.com/authorize";
const tokenEndpoint = "https://accounts.spotify.com/api/token";
const typeReponse = "code";
const grantType = "authorization_code";
const scopes = "playlist-modify-private,user-library-read";
const requestLimit = 50;

export default {
  ID_CLIENT: idClient,
  REDIRECT_URI: redirectUri,
  AUTH_ENDPOINT: authEndpoint,
  TOKEN_ENDPOINT: tokenEndpoint,
  TYPE_REPONSE: typeReponse,
  GRANT_TYPE: grantType,
  SCOPES: scopes,
  REQUEST_LIMIT: requestLimit,
};
