<template>
  <div v-if="spotifyStore.accessToken">
    <div class="container-page" v-if="chargement">
      <h2 class="titre-page">{{ $t("generation.titreChargement") }}</h2>
      <div class="separateur"></div>
      <Chargement />
    </div>
    <div class="container-page" v-else>
      <h2 class="titre-page">{{ $t("generation.titre") }}</h2>

      <div class="separateur"></div>

      <!-- Nom de la liste -->
      <h3 class="titre-etape">{{ $t("generation.etape1.titre") }}</h3>
      <h4 class="sous-titre-etape">{{ $t("generation.etape1.sousTitre") }}</h4>
      <input
        class="champ-texte"
        :class="erreursFormulaire.nomListe ? 'champ-erreur' : ''"
        type="text"
        :placeholder="$t('generation.etape1.placeholder')"
        v-model="nomListe"
        v-on:change="validerFormulaire(true, false, false)"
      />
      <div v-if="erreursFormulaire.nomListe" class="erreur">{{ $t("generation.etape1.erreur") }}</div>

      <div class="separateur"></div>

      <!-- Choix des artistes -->
      <h3 class="titre-etape">{{ $t("generation.etape2.titre") }}</h3>
      <h4 class="titre-etape sous-titre-etape">{{ $t("generation.etape2.sousTitre") }}</h4>
      <span class="sous-titre-etape">{{ $t("generation.etape2.sousTitre2") }}</span>
      <br /><br />
      <div v-for="artiste in selectionArtistes" :key="artiste.id">
        <CarteArtiste :nomGroupe="artiste.name" :urlImage="artiste.images[0]?.url" :modeAjout="false" v-on:suppression="supprimerArtiste(artiste)" />
      </div>
      <div v-if="selectionArtistes.length > 0" class="separateur"></div>
      <input
        class="champ-texte barre-recherche"
        :class="erreursFormulaire.selectionArtistes ? 'champ-erreur' : ''"
        type="text"
        :placeholder="$t('generation.etape2.placeholder')"
        v-on:input="lancerRecherche"
        v-model="recherche.motsCles"
      />
      <div v-if="erreursFormulaire.selectionArtistes" class="erreur">{{ $t("generation.etape2.erreur") }}</div>
      <div v-if="recherche.chargement || recherche.resultat.length > 0" class="separateur"></div>
      <div v-if="!recherche.chargement">
        <div v-for="artiste in recherche.resultat" :key="artiste.id">
          <CarteArtiste
            :nomGroupe="artiste.name"
            :urlImage="artiste.images[0]?.url"
            :urlSpotify="artiste.external_urls.spotify"
            v-on:ajout="ajouterArtiste(artiste)"
          />
        </div>
        <div class="credits-spotify" v-if="recherche.resultat.length > 0">
          {{ $t("generation.etape3.credits") }}
          <br />
          <a href="https://spotify.com" target="_blank">
            <img v-if="optionsStore.theme == 'dark'" class="logo-spotify" src="@/assets/images/Spotify_logo_full_white.png" width="85" />
            <img v-else class="logo-spotify" src="@/assets/images/Spotify_logo_full_green.png" width="85" />
          </a>
        </div>
      </div>
      <div v-else>
        <Chargement />
      </div>

      <div class="separateur"></div>

      <!-- Familiarité ou nouveauté -->
      <h3 class="titre-etape">{{ $t("generation.etape3.titre") }}</h3>
      <h4 class="sous-titre-etape">{{ $t("generation.etape3.sousTitre") }}</h4>
      <input type="radio" id="equilibre" name="familiarite-nouveaute" v-model="familiariteNouveaute" :value="enumFamiliariteNouveaute.equilibre" />
      <label for="equilibre"
        ><strong>{{ $t("generation.etape3.options.equilibre.titre") }}</strong
        ><br /><span v-html="$t('generation.etape3.options.equilibre.description')"></span
      ></label>
      <br /><br />
      <input
        type="radio"
        id="familiarite"
        name="familiarite-nouveaute"
        v-model="familiariteNouveaute"
        :value="enumFamiliariteNouveaute.familiarite"
      />
      <label for="familiarite"
        ><strong>{{ $t("generation.etape3.options.familiarite.titre") }}</strong
        ><br /><span v-html="$t('generation.etape3.options.familiarite.description')"></span
      ></label>
      <br /><br />
      <input type="radio" id="nouveaute" name="familiarite-nouveaute" v-model="familiariteNouveaute" :value="enumFamiliariteNouveaute.nouveaute" />
      <label for="nouveaute"
        ><strong>{{ $t("generation.etape3.options.nouveaute.titre") }}</strong
        ><br /><span v-html="$t('generation.etape3.options.nouveaute.description')"></span
      ></label>

      <div class="separateur"></div>

      <!-- Durée de la liste -->
      <h3 class="titre-etape">{{ $t("generation.etape4.titre") }}</h3>
      <h4 class="titre-etape sous-titre-etape">{{ $t("generation.etape4.sousTitre") }}</h4>
      <span class="sous-titre-etape">{{ $t("generation.etape4.sousTitre2") }}</span>
      <br /><br />
      <div class="container-duree">
        <input
          class="champ-texte champ-duree"
          :class="erreursFormulaire.duree ? 'champ-erreur' : ''"
          type="text"
          v-maska
          data-maska="##"
          placeholder="00"
          v-model="dureeHeures"
          v-on:change="validerFormulaire(false, false, true)"
        />
        <span>&nbsp;{{ $t("generation.etape4.heures") }}</span>
      </div>
      <div class="container-duree">
        <input
          class="champ-texte champ-duree"
          :class="erreursFormulaire.duree ? 'champ-erreur' : ''"
          type="text"
          v-maska
          data-maska="##"
          placeholder="00"
          v-model="dureeMinutes"
          v-on:change="validerFormulaire(false, false, true)"
        />
        <span>&nbsp;{{ $t("generation.etape4.minutes") }}</span>
      </div>
      <div v-if="erreursFormulaire.duree" class="erreur" style="text-align: center">{{ $t("generation.etape4.erreur") }}</div>

      <div class="separateur"></div>

      <!-- Générer la liste -->
      <h3 class="titre-etape">{{ $t("generation.etape5.titre") }}</h3>
      <h4 class="sous-titre-etape">{{ $t("generation.etape5.sousTitre") }}</h4>
      <button :class="`bouton bouton-${chargement ? 'inactif' : 'actif'}`" v-on:click="genererPlaylist" :disabled="chargement">
        {{ $t("generation.etape5.btnGenerer") }}
      </button>
      <br /><br />
    </div>
  </div>
</template>

<script>
import spotifyApiRoutes from "../constants/spotifyApiRoutes";
import { useSpotifyStore } from "@/stores/spotifyStore";
import { useOptionsStore } from "@/stores/optionsStore";
import CarteArtiste from "../components/GenerationView/CarteArtiste.vue";
import Chargement from "../components/Utilitaires/Chargement.vue";
import axios from "axios";
import spotifyConfig from "../constants/spotifyConfig";
import familiariteNouveaute from "../enums/familiariteNouveaute";

export default {
  name: "GenerationView",
  setup() {
    const spotifyStore = useSpotifyStore();
    const optionsStore = useOptionsStore();
    return { spotifyStore, optionsStore };
  },
  components: {
    CarteArtiste,
    Chargement,
  },
  data() {
    return {
      chargement: false,
      dureeHeures: null,
      dureeMinutes: null,
      enumFamiliariteNouveaute: familiariteNouveaute,
      erreursFormulaire: {
        selectionArtistes: false,
        duree: false,
        nomListe: false,
      },
      familiariteNouveaute: familiariteNouveaute.equilibre,
      nomListe: null,
      recherche: {
        motsCles: null,
        timeout: null,
        chargement: false,
        resultat: [],
      },
      selectionArtistes: [],
    };
  },
  methods: {
    async rechercheArtistes() {
      if (this.recherche.motsCles == null || this.recherche.motsCles == "") {
        this.recherche.resultat = [];
        return;
      }

      const retour = await axios.get(spotifyApiRoutes.URL_RECHERCHE, {
        headers: { Authorization: `Bearer ${this.spotifyStore.accessToken}` },
        params: {
          q: this.recherche.motsCles,
          type: "artist",
          limit: 5,
        },
      });

      this.recherche.resultat = retour.data.artists.items;
    },
    lancerRecherche() {
      if (this.recherche.timeout) clearTimeout(this.recherche.timeout);
      this.recherche.chargement = true;
      this.recherche.timeout = setTimeout(async () => {
        await this.rechercheArtistes();
        this.recherche.chargement = false;
      }, 2000);
    },
    ajouterArtiste(artiste) {
      if (!this.selectionArtistes.includes(artiste) && this.selectionArtistes.length < 10) this.selectionArtistes.push(artiste);
      this.validerFormulaire(false, true, false);
    },
    supprimerArtiste(artiste) {
      this.selectionArtistes = this.selectionArtistes.filter((a) => a.id !== artiste.id);
      this.validerFormulaire(false, true, false);
    },
    validerFormulaire(nomListe = true, selectionArtistes = true, duree = true) {
      if (nomListe) this.erreursFormulaire.nomListe = this.nomListe == null || this.nomListe.trim() === "";
      if (selectionArtistes) this.erreursFormulaire.selectionArtistes = this.selectionArtistes.length <= 0;
      if (duree) this.erreursFormulaire.duree = this.duree <= 0;

      return !(this.erreursFormulaire.nomListe || this.erreursFormulaire.selectionArtistes || this.erreursFormulaire.duree);
    },
    async genererPlaylist() {
      if (!this.validerFormulaire()) return;

      this.chargement = true;

      // Créer la playlist vide
      const description = this.selectionArtistes.map((artiste) => artiste.name).join(", ");
      const playlist = await axios.post(
        spotifyApiRoutes.URL_PLAYLISTS.replace("{user_id}", this.spotifyStore.userId),
        {
          name: this.nomListe,
          description: description,
          public: false,
        },
        { headers: { Authorization: `Bearer ${this.spotifyStore.accessToken}` } }
      );

      this.spotifyStore.lienDerniereListe = playlist.data.external_urls.spotify;

      // Un problème avec l'API de Spotify fait que la description ne se met pas toujours à jour correctement
      // Réessayer jusqu'à ce que la description soit correcte
      let nbEssais = 0;
      if (playlist.data.description == null || playlist.data.description == "") {
        let descriptionCourante = null;
        do {
          await axios.put(
            spotifyApiRoutes.URL_PLAYLIST.replace("{playlist_id}", playlist.data.id),
            { description: description },
            { headers: { Authorization: `Bearer ${this.spotifyStore.accessToken}` } }
          );
          const playlistUpdate = await axios.get(spotifyApiRoutes.URL_PLAYLIST.replace("{playlist_id}", playlist.data.id), {
            headers: { Authorization: `Bearer ${this.spotifyStore.accessToken}` },
          });
          descriptionCourante = playlistUpdate.data.description;
          nbEssais++;
          new Promise((res) => setTimeout(res, 200));
        } while ((descriptionCourante == null || descriptionCourante == "") && nbEssais < 10);
      }

      // Obtenir les chansons à ajouter à la playlist
      if (this.familiariteNouveaute != familiariteNouveaute.equilibre && this.spotifyStore.userLikedSongs == null)
        await this.spotifyStore.definirUserLikedSongs();

      for (let i = 0; i < this.selectionArtistes.length; i++) {
        this.selectionArtistes[i].duree = 0;
        this.selectionArtistes[i].resteChansons = true;
        this.selectionArtistes[i].chansonsApi = [];
        this.selectionArtistes[i].chansonsIgnorees = [];
        this.selectionArtistes[i].titresChansons = [];
        this.selectionArtistes[i].chansonCourante = 0;
      }

      let urisChansonsTotal = [];
      let pageCourante = 0;
      while (
        this.selectionArtistes.some((d) => d.duree < this.dureeArtiste && d.resteChansons) &&
        this.dureeTotalePlaylistCourante < this.duree - 5 &&
        pageCourante < 1000 // Limite de l'API de Spotify
      ) {
        await this.obtenirChansonsApi(pageCourante);
        let urisChansons = this.selectionnerChansonsPlaylist(pageCourante, false);
        urisChansonsTotal = urisChansonsTotal.concat(urisChansons);
        pageCourante += spotifyConfig.REQUEST_LIMIT;
      }
      if (this.dureeTotalePlaylistCourante < this.duree - 5)
        urisChansonsTotal = urisChansonsTotal.concat(this.selectionnerChansonsPlaylist(null, true));
      urisChansonsTotal = this.melangerListe(urisChansonsTotal);

      // Ajouter les chansons à la playlist
      let promises = [];
      for (let i = 0; i < urisChansonsTotal.length; i += 100) {
        promises.push(
          axios.post(
            spotifyApiRoutes.URL_CHANSONS.replace("{playlist_id}", playlist.data.id),
            {
              uris: urisChansonsTotal.slice(i, Math.min(i + 100, urisChansonsTotal.length)),
              position: 0,
            },
            { headers: { Authorization: `Bearer ${this.spotifyStore.accessToken}` } }
          )
        );
      }
      await Promise.all(promises);

      this.chargement = false;
      this.$router.push({ name: "Succes" });
    },
    async obtenirChansonsApi(pageCourante) {
      let promises = [];

      for (let i = 0; i < this.selectionArtistes.length; i++) {
        promises.push(
          axios
            .get(spotifyApiRoutes.URL_RECHERCHE, {
              headers: { Authorization: `Bearer ${this.spotifyStore.accessToken}` },
              params: {
                q: "artist:" + this.selectionArtistes[i].name,
                type: "track",
                limit: spotifyConfig.REQUEST_LIMIT,
                offset: pageCourante,
              },
            })
            .then((retour) => {
              this.selectionArtistes[i].chansonsApi.push(...retour.data.tracks.items);
            })
        );
      }

      await Promise.all(promises);
    },
    obtenirIdxArtiste(artiste) {
      return this.selectionArtistes.findIndex((a) => a.id === artiste.id);
    },
    selectionnerProchaineChanson(artiste, pageCourante) {
      let chansonRetour = null;
      const idxArtiste = this.obtenirIdxArtiste(artiste);

      do {
        // Arrêter s'il n'y a plus de chansons à retourner
        if (artiste.chansonCourante > artiste.chansonsApi.length - 1) {
          if (artiste.chansonsApi.length < spotifyConfig.REQUEST_LIMIT * pageCourante) this.selectionArtistes[idxArtiste].resteChansons = false;
          break;
        }

        const chanson = artiste.chansonsApi[artiste.chansonCourante];
        artiste.chansonCourante++;

        // Puisque l'appel à l'API est une recherche, d'autres artistes peuvent parfois sortir dans les résultats
        if (!chanson.artists.map((a) => a.id).includes(artiste.id)) continue;

        // Si l'option Familiarité ou l'option Nouveauté est cochée, comparer avec les chansons likées
        if (this.familiariteNouveaute == familiariteNouveaute.familiarite && !this.spotifyStore.userLikedSongs.some((x) => x.uri === chanson.uri))
          continue;
        if (
          this.familiariteNouveaute == familiariteNouveaute.nouveaute &&
          this.spotifyStore.userLikedSongs.some((x) => x.artist === chanson.artists[0].id && x.name === chanson.name)
        )
          continue;

        // Ajouter du random pour ne pas toujours générer la même chose
        const random = Math.floor(Math.random() * 3);
        if (random == 0) this.selectionArtistes[idxArtiste].chansonsIgnorees.push(artiste.chansonCourante - 1);
        else chansonRetour = chanson;
      } while (chansonRetour == null);

      return chansonRetour;
    },
    selectionnerChansonIgnoree(artiste) {
      const idxArtiste = this.obtenirIdxArtiste(artiste);
      const chansonsIgnorees = this.selectionArtistes[idxArtiste].chansonsIgnorees;
      if (chansonsIgnorees.length === 0) return null;

      const random = Math.floor(Math.random() * chansonsIgnorees.length);
      const chanson = this.selectionArtistes[idxArtiste].chansonsApi[chansonsIgnorees[random]];
      this.selectionArtistes[idxArtiste].chansonsIgnorees.splice(random, 1);
      return chanson;
    },
    selectionnerChansonsPlaylist(pageCourante, utiliserIgnorees) {
      let urisChansons = [];
      while (this.dureeTotalePlaylistCourante < this.duree - 5) {
        const dureeMin = this.selectionArtistes.reduce((min, artiste) => (artiste.duree < min ? artiste.duree : min), Infinity);
        const artistesPlusPetiteDuree = this.selectionArtistes.filter((a) => a.duree === dureeMin);
        const idxArtiste = this.obtenirIdxArtiste(artistesPlusPetiteDuree[0]);
        const chanson = utiliserIgnorees
          ? this.selectionnerChansonIgnoree(artistesPlusPetiteDuree[0])
          : this.selectionnerProchaineChanson(artistesPlusPetiteDuree[0], pageCourante);

        // Plus de chanson dans la liste obtenue par l'API / dans la liste des chansons ignorées
        if (chanson == null) break;

        // Puisqu'une chanson peut exister plusieurs fois (ex: singles), ne pas ajouter 2 fois le même titre
        if (this.selectionArtistes[idxArtiste].titresChansons.includes(chanson.name)) continue;

        // Ne jamais permettre de dépasser la durée spécifiée de plus de 5 minutes
        if (this.dureeTotalePlaylistCourante + chanson.duration_ms / 1000 / 60 > this.duree + 5) continue;

        // Ajouter la chanson à la liste et modifier l'artiste en conséquence
        this.selectionArtistes[idxArtiste].duree += chanson.duration_ms / 1000 / 60;
        this.selectionArtistes[idxArtiste].titresChansons.push(chanson.name);
        urisChansons.push(chanson.uri);
      }
      return urisChansons;
    },
    melangerListe(liste) {
      for (let i = 0; i < liste.length; i++) {
        let random = Math.floor(Math.random() * i);
        [liste[i], liste[random]] = [liste[random], liste[i]];
      }
      return liste;
    },
    obtenirNombreDuree(nombre) {
      return isNaN(parseInt(nombre)) ? 0 : parseInt(nombre);
    },
  },
  computed: {
    motsClesRecherche() {
      return this.recherche.motsCles;
    },
    duree() {
      return Math.min(this.obtenirNombreDuree(this.dureeHeures) * 60 + this.obtenirNombreDuree(this.dureeMinutes), 1200);
    },
    dureeArtiste() {
      // La méthode de répartition fait en sorte que les listes sont toujours un peu plus longue que la durée spécifiée
      // Enlever quelques minutes permet donc de plus se rapprocher de cette durée
      return this.duree / this.selectionArtistes.length - ((6 / 9) * (this.selectionArtistes.length - 1) + 2);
    },
    dureeTotalePlaylistCourante() {
      let total = 0;
      this.selectionArtistes.forEach((d) => (total += d.duree));
      return total;
    },
  },
  watch: {
    dureeHeures(heures) {
      if (this.obtenirNombreDuree(heures) > 20) this.dureeHeures = 20;
      if (this.obtenirNombreDuree(heures) == 20) this.dureeMinutes = "00";
    },
    dureeMinutes(minutes) {
      if (this.obtenirNombreDuree(minutes) > 59) this.dureeMinutes = 59;
      if (this.dureeHeures == 20) this.dureeMinutes = "00";
    },
  },
};
</script>

<style scoped>
.titre-etape {
  margin-bottom: 0;
}

.sous-titre-etape {
  margin-top: 0.25rem;
}

.champ-texte {
  width: calc(100% - 20px);
  border: 2px solid grey;
  border-radius: 10px;
  height: 25px;
  padding: 0 10px;
  color: var(--couleur-texte);
  background-color: var(--couleur-fond);
}

.champ-texte::placeholder {
  color: grey;
}

.champ-erreur {
  border: 2px solid red;
}

.barre-recherche {
  width: calc(100% - 40px);
  background: url("../assets/images/Icone_recherche.png") no-repeat right;
  background-size: 26px;
  padding-right: 30px;
}

.credits-spotify {
  font-size: 0.85rem;
  margin-top: 0.65rem;
}

.credits-spotify > a > img {
  margin-top: 0.5rem;
}

.container-duree {
  display: inline-block;
  width: fit-content;
  margin: 5px 5px 0 5px;
}

.champ-duree {
  width: 16px;
}

.erreur {
  color: red;
  text-align: left;
  margin-top: 0.25rem;
  font-size: 0.85rem;
}
</style>
