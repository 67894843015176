<template>
  <Navbar />
  <router-view />
  <Footer />
</template>

<script>
import Navbar from "./components/Gabarit/Navbar.vue";
import Footer from "./components/Gabarit/Footer.vue";
import { useSpotifyStore } from "@/stores/spotifyStore";
import { useOptionsStore } from "@/stores/optionsStore";

export default {
  setup() {
    const spotifyStore = useSpotifyStore();
    const optionsStore = useOptionsStore();
    return { spotifyStore, optionsStore };
  },
  components: {
    Navbar,
    Footer,
  },
  created() {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) this.spotifyStore.accessToken = accessToken;
    const userId = sessionStorage.getItem("userId");
    if (userId) this.spotifyStore.userId = userId;

    const theme = localStorage.getItem("theme") === "true" ? "dark" : "light";
    document.documentElement.setAttribute("theme", theme);
    this.optionsStore.theme = theme;

    const langueValeur = localStorage.getItem("langue");
    const langueNavigateur = navigator.language.substring(0, 2);
    const languesSupportees = ["en", "fr"];
    if (langueValeur) {
      const langue = langueValeur === "true" ? "en" : "fr";
      this.$i18n.locale = langue;
      this.optionsStore.langue = langue;
    } else if (languesSupportees.some((langue) => langue === langueNavigateur)) {
      this.$i18n.locale = langueNavigateur;
      this.optionsStore.langue = langueNavigateur;
    }
  },
};
</script>

<style>
@import "./assets/css/main.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
