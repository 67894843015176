<template>
  <div class="card-container">
    <div class="container-img">
      <img v-if="urlImage" :src="urlImage" alt="Image du groupe" />
      <img v-else src="@/assets/images/Artiste_sans_image.png" alt="Image du groupe" />
    </div>
    <div class="container-groupe" v-on:click="ouvrirSpotify">
      <div class="nom-groupe">{{ nomGroupe }}</div>
    </div>
    <div class="container-btn">
      <button :class="modeAjout ? 'btn btn-ajouter' : 'btn btn-retirer'" v-on:click="emitAjoutSuppression">
        {{ modeAjout ? "+" : "×" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarteArtiste",
  props: {
    modeAjout: {
      type: Boolean,
      default: true,
    },
    nomGroupe: {
      type: String,
      default: null,
    },
    urlImage: {
      type: String,
      default: null,
    },
    urlSpotify: {
      type: String,
      default: null,
    },
  },
  methods: {
    emitAjoutSuppression() {
      this.modeAjout ? this.$emit("ajout") : this.$emit("suppression");
    },
    ouvrirSpotify() {
      window.open(this.urlSpotify, "_blank");
    },
  },
};
</script>

<style scoped>
.card-container {
  border: 2px solid grey;
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 45px;
  width: 100%;
  margin-bottom: 0.25rem;
}

.container-img {
  width: 15%;
  text-align: left;
  padding-left: 5px;
}

.container-img > img {
  height: 35px;
  width: 35px;
  object-fit: cover;
  vertical-align: bottom;
}

.container-groupe {
  width: 70%;
  height: fit-content;
  padding: 5px;
  text-align: center;
  font-size: 16px;
}

.nom-groupe {
  line-height: 1;
  cursor: pointer;
}

.container-btn {
  width: 15%;
  text-align: right;
  padding-right: 5px;
}

.btn {
  height: 35px;
  width: 35px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 30px;
  line-height: 30px;
  cursor: pointer;
}

.btn-ajouter {
  background-color: var(--couleur-accent);
}

.btn-retirer {
  background-color: #b30000;
}
</style>
