import { createRouter, createWebHistory } from "vue-router";
import generationView from "../views/GenerationView.vue";
import connexionView from "../views/ConnexionView.vue";
import succesView from "../views/SuccesView.vue";
import redirectionConnexionView from "../views/RedirectionConnexionView.vue";
import { useSpotifyStore } from "@/stores/spotifyStore";

const routes = [
  {
    path: "/",
    name: "Generation",
    component: generationView,
    beforeEnter: async (to, from, next) => {
      const spotifyStore = useSpotifyStore();
      if (!spotifyStore.accessToken) next("/connexion");
      else next();
    },
  },
  {
    path: "/connexion",
    name: "Connexion",
    component: connexionView,
  },
  {
    path: "/succes",
    name: "Succes",
    component: succesView,
    beforeEnter: async (to, from, next) => {
      const spotifyStore = useSpotifyStore();
      if (!spotifyStore.accessToken) next("/connexion");
      if (!spotifyStore.lienDerniereListe) next("/");
      else next();
    },
  },
  {
    path: "/redirection-connexion",
    name: "RedirectionConnexion",
    component: redirectionConnexionView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
