<template>
  <div class="container-page">
    <h2 class="titre-page">{{ $t("succes.titre") }}</h2>

    <div class="separateur"></div>

    <a :href="lienDerniereListe" target="_blank" class="bouton bouton-actif" style="display: block"> {{ $t("succes.btnAccederListe") }} </a>
    <br />
    <button class="bouton bouton-actif" v-on:click="genererAutreListe">{{ $t("succes.btnGenererAutreListe") }}</button>
  </div>
</template>

<script>
import { useSpotifyStore } from "@/stores/spotifyStore";

export default {
  name: "SuccesView",
  setup() {
    const spotifyStore = useSpotifyStore();
    return { spotifyStore };
  },
  methods: {
    genererAutreListe() {
      this.$router.push({ name: "Generation" });
    },
  },
  computed: {
    lienDerniereListe() {
      return this.spotifyStore.lienDerniereListe;
    },
  },
};
</script>
